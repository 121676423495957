import React, { useState } from "react"
import "../static/style/componentStyle/blogs.scss"
import "../static/style/componentStyle/navbar.scss"
import "../static/style/componentStyle/blogs.scss"
import Loadable from "@loadable/component";
import { Helmet } from "react-helmet";



const Navbar = Loadable(() => import("./navbar"));
const NavMenu = Loadable(() => import("./nav-menu"));
const FooterCmp = Loadable(() => import("./footerCmp"));


export default function Layout({children }) {
    const [isNavBarOpen, setIsNavBarOpen] = useState(true);
    const closeNavMenu = () => {
      setIsNavBarOpen(false);
    };
    const title = "Pryzm - A Data Reliability & Observability Platform | Powered by LUMIQ";
    const description = "Pryzm is a data reliability platform, crafted to ensure precision and trust in every data point."
    return (
      <div className={`${isNavBarOpen ? "" : "no-scroll"}`}>
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="Lumiq.ai" />
          <title>{title}</title>
          <meta name="description" content={description} />
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-14EM13H4Q8"
            ></script>
            <script>
              {`
          window.dataLayer = window.dataLayer || [];   
          function gtag(){
            dataLayer.push(arguments);
          }   
          gtag('js', new Date());   
          gtag('config', 'G-14EM13H4Q8'); 
          `}
            </script>
        </Helmet>
        {isNavBarOpen && (
          <Navbar
            isNavBarOpen={isNavBarOpen}
            setIsNavBarOpen={setIsNavBarOpen}
          />
        )}
        {!isNavBarOpen && (
          <NavMenu
            isNavBarOpen={isNavBarOpen}
            setIsNavBarOpen={setIsNavBarOpen}
            closeNavMenu={closeNavMenu}
          />
        )}
        {/* {isNavBarOpen && (
                <NavMobile  isNavBarOpen={isNavBarOpen} setIsNavBarOpen={setIsNavBarOpen}/>
            )} */}
        <main>{children}</main>
        <FooterCmp />
      </div>
    )
}
